import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GENDERS, NON_CONFORMING_TITLES } from '@constants';
import { RegistrationDetails } from '@models/registration-details.model';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { ConfigLocaleService, ConfigRegex } from '@services/configLocale.service';
import { FeedbackService } from '@services/feedback.service';
import { UserService } from '@services/user.service';
import { passwordValidator } from '@validators/password.validator';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'gc-register-first-step',
  templateUrl: './register-first-step.component.html',
  styleUrls: ['./register-first-step.component.scss'],
})
export class RegisterFirstStepComponent implements OnInit {
  registerForm: FormGroup;
  maxDate = new Date();
  showPassword = false;
  idHidden = false;
  idRequired = false;

  @Output() stepComplete = new EventEmitter<void>();
  constructor(
    private readonly formBuilder: FormBuilder,
    public feedbackService: FeedbackService,
    public auth: AuthService,
    private readonly userService: UserService,
    private readonly alert: AlertService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly configService: ConfigLocaleService,
  ) {}

  get genderOptions() {
    return GENDERS;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      cellnr: [''],
      idnumber: [''],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), passwordValidator])],
      dob: [''],
      gender: [''],
    });

    // If id_hidden is set to true, hide the id number field and remove the validators, else, get the validators from the config service
    this.configService
      .getValueByKey$(['id_hidden'])
      .pipe(
        switchMap((config) => {
          this.idHidden = config.id_hidden === 'true';
          if (this.idHidden) {
            this.registerForm.get('idnumber').clearValidators();
            this.registerForm.get('idnumber').updateValueAndValidity();
            return EMPTY;
          } else {
            return this.configService.getValueByKey$(['id_number_validators_json']);
          }
        }),
        switchMap((config) => {
          const idNumberValidators = JSON.parse(config.id_number_validators_json) as ConfigRegex;
          this.registerForm.get('idnumber').addValidators(Validators.pattern(new RegExp(idNumberValidators.pattern)));
          if (idNumberValidators.required) {
            this.idRequired = true;
            this.registerForm.get('idnumber').addValidators(Validators.required);
          }
          return EMPTY;
        }),
      )
      .subscribe();

    // cellnr validators
    this.configService.getValueByKey$(['cell_validators_json']).subscribe((config) => {
      const cellValidators = JSON.parse(config.cell_validators_json) as ConfigRegex;
      this.registerForm.get('cellnr').addValidators(Validators.pattern(new RegExp(cellValidators.pattern)));
      if (cellValidators.required) {
        this.registerForm.get('cellnr').addValidators(Validators.required);
      }
    });
  }

  googleSignIn() {
    this.auth.authenticated$.pipe(distinctUntilChanged()).subscribe((authenticated) => {
      if (authenticated) {
        this.zone.run(() => {
          this.router.navigate(['/complete-details']);
        });
      }
    });
  }

  populateDobAndGender() {
    if (!this.registerForm.value.idnumber) {
      return;
    }
    const { dob, gender } = this.userService.extractDobAndGender(this.registerForm.value.idnumber);
    if (this.registerForm.value.dob !== dob) {
      this.registerForm.get('dob').setValue(dob);
    }
    if (this.registerForm.value.gender !== gender && !NON_CONFORMING_TITLES.includes(this.registerForm.value.title)) {
      this.registerForm.get('gender').setValue(gender);
    }
  }

  get passwordErrors(): { number: boolean; letter: boolean; password: boolean } {
    return this.registerForm.get('password').getError('passwordValidator');
  }

  register() {
    if (this.registerForm.valid) {
      const dob = this.registerForm.value.dob ? moment(this.registerForm.value.dob).format('YYYY-MM-DD') : '';
      const registrationForm: RegistrationDetails = {
        ...this.registerForm.value,
        dob,
        initials: this.userService.extractInitials(this.registerForm.get('name').value),
      };
      this.auth
        .register$(registrationForm)
        .pipe(
          catchError((err: unknown) => {
            this.alert.handleErrorDialog$(err, 'Unable to register');
            return null;
          }),
        )
        .subscribe((res) => {
          if (res) {
            this.stepComplete.emit();
          }
        });
    } else {
      this.registerForm.markAllAsTouched();
    }
  }

  feedback() {
    this.feedbackService.feedbackDialog(feedbackTypes.register).subscribe();
  }
}
