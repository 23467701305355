import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingType } from '@models/booking-type.model';
import { Doctor } from '@models/doctor.model';
import { AuthService } from '@services/auth.service';
import { DisplayService } from '@services/display.service';
import { DoctorService } from '@services/doctor.service';

@Component({
  selector: 'gc-doctor-card',
  templateUrl: './doctor-card.component.html',
  styleUrls: ['./doctor-card.component.scss'],
})
export class DoctorCardComponent implements OnInit {
  @Input() doctor: Doctor;
  @Input() minimal = false;

  isSaved: boolean;

  constructor(
    private readonly auth: AuthService,
    private readonly disp: DisplayService,
    private readonly doctorService: DoctorService,
    private readonly router: Router,
  ) {}

  get isAuthed$() {
    return this.auth.authenticated$;
  }

  get isMobile$() {
    return this.disp.isMobile$;
  }

  ngOnInit() {
    this.isSaved = this.doctor.isSaved;
  }

  filterBookingTypes(bookingTypes: BookingType[]): BookingType[] {
    if (bookingTypes) {
      const newBookingTypes: BookingType[] = [];
      bookingTypes.forEach((bookingType) => {
        const index = newBookingTypes.findIndex((bookingTypeIcon) => bookingTypeIcon.icon === bookingType.icon);
        if (index === -1) {
          newBookingTypes.push(bookingType);
        }
      });
      return newBookingTypes;
    }
    return bookingTypes;
  }

  saveDoctor() {
    if (this.auth.isAuthenticated()) {
      this.doctorService.saveDoctor$({ identifier: this.doctor.uuid || this.doctor.code, save: !this.isSaved }).subscribe((isSaved) => {
        this.isSaved = isSaved;
      });
    }
  }

  public makeBooking(uuid: string, bookingType?: string, timeslot?: string) {
    if (this.router.url.startsWith('/external')) {
      this.router.navigate(['/external/diary/' + uuid], { queryParams: { bookingType, timeslot } });
    } else {
      const bookingUrl = this.auth.isAuthenticated() ? '/bookings/make-booking' : '/doctors/make-booking';
      this.router.navigate([bookingUrl], { queryParams: { doctor: uuid, bookingType, timeslot } });
    }
  }

  updateTimeslots() {
    this.doctorService.updateBookingTypes$(this.doctor).subscribe();
  }
}
