import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@modules/material/material.module';
import { SharedModule } from '@modules/shared/shared.module';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { TopNavBarComponent } from './components/topnavbar/topnavbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { TelehealthOverlayComponent } from './pages/telehealth/telehealth-overlay/telehealth-overlay.component';
import { TelehealthComponent } from './pages/telehealth/telehealth.component';
import { SessionRoutingModule } from './session-routing.module';
import { SessionComponent } from './session.component';

@NgModule({
  declarations: [
    SessionComponent,
    DashboardComponent,
    NotificationsComponent,
    SearchResultsComponent,
    TopNavBarComponent,
    TelehealthComponent,
    TelehealthOverlayComponent,
  ],
  imports: [CommonModule, MaterialModule, SharedModule, SessionRoutingModule],
  entryComponents: [],
})
export class SessionModule {}
