<div fxLayoutGap="16px" class="change-email-page" fxLayoutAlign="space-around center">
  <div *ngIf="validCode$ | async; else showError">
    <mat-card>
      <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
        <mat-card-title>Hello, welcome back!</mat-card-title>
        <mat-card-subtitle fxLayoutAlign="center center" fxHide.gt-sm>
          <span>
            Log in to continue to your
            <span class="mygc-text">myGC</span>
            account
          </span>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
        <p>Please enter your current email address and password to change your email address.</p>
        <form [formGroup]="loginForm" class="login-form" (submit)="updateEmail('password')">
          <mat-form-field>
            <mat-label>Email Address</mat-label>
            <input matInput type="email" autocomplete="email" formControlName="email" gcFormField />
            <mat-error>Please enter a valid email address</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              autocomplete="current-password"
              formControlName="password"
              gcFormField
            />
            <button mat-icon-button matSuffix type="button" *ngIf="loginForm.get('password').value" (click)="showPassword = !showPassword">
              <mat-icon [svgIcon]="showPassword ? 'eye-off' : 'eye'"></mat-icon>
            </button>
            <mat-error>Password is required</mat-error>
          </mat-form-field>
          <button mat-raised-button color="accent" type="submit" [disabled]="loginForm.invalid">Submit</button>
        </form>
        <div class="divider"><span>OR</span></div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
          <gc-grower icon="google" title="Log in with Google" isButton="true" (click)="updateEmail('google')"></gc-grower>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <ng-template #showError>
    <mat-card>
      <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
        <mat-card-title>Invalid code</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
        <p>The link you have clicked on is no longer valid.</p>
        <button mat-button type="button" routerLink="/dashboard">Go to Dashboard</button>
      </mat-card-content>
    </mat-card>
  </ng-template>
</div>
