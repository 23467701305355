<form [formGroup]="passwordForm" fxLayout="row" fxLayoutAlign="center">
  <mat-card>
    <mat-card-header
      ngClass.lt-md="mobile-header"
      ngClass.gt-sm="desktop-header"
      fxLayout="column"
      fxLayoutAlign.lt-md="center center"
      fxLayoutAlign.gt-sm="center end"
    >
      <mat-card-title fxHide.lt-md>Reset your password</mat-card-title>
      <mat-card-subtitle>Select which details we should use to reset your password:</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="reset-password-field">
        <div class="cell" fxLayout="row" fxLayoutGap="10px">
          <mat-icon svgIcon="cellphone"></mat-icon>
          <span>via SMS:</span>
        </div>
        <gc-input-field formControlName="cellnr" type="tel" name="mobile" autocomplete="mobile" label="Mobile number"></gc-input-field>
      </div>
      <div class="reset-password-field">
        <div class="email" fxLayout="row" fxLayoutGap="10px">
          <mat-icon svgIcon="email-outline"></mat-icon>
          <span>via Email:</span>
        </div>
        <gc-input-field formControlName="email" type="email" name="email" autocomplete="email" label="Email address"></gc-input-field>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="forgotPassword()">
        <mat-icon svgIcon="send"></mat-icon>
        Send
      </button>
    </mat-card-actions>
  </mat-card>
</form>
