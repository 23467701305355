import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'gc-grower',
  templateUrl: './grower.component.html',
  styleUrls: ['./grower.component.scss'],
})
export class GrowerComponent {
  @Input() color = 'primary';
  @Input() icon: string;
  @Input() title: string;
  @Input() href: string;
  @Input() disabled: boolean;
  @Input() isButton = false;

  @HostListener('click')
  onClick() {
    if (!this.isButton && this.href) {
      window.open(this.href, '_blank');
    }
  }
}
