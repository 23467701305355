import * as moment from 'moment';
import { Duration } from 'moment';
import { BookingType, BookingTypeData } from './booking-type.model';
import { Doctor, DoctorData } from './doctor.model';
import { User, UserData } from './user.model';

export interface BookingDocuments {
  questionnaire: boolean;
  consent_form: boolean;
  demographic_request: boolean;
}

export interface BookingData {
  doctor: DoctorData;
  duration: Duration;
  date_time: string;
  type: BookingTypeData;
  user: UserData;
  uuid?: string;
  which_documents: BookingDocuments;
  auth_package_toggle: BookingDocuments;
  telemed_room?: string;
}

export class Booking {
  readonly uuid: string;
  readonly telemedRoom: string;
  doctor: Doctor;
  duration: Duration;
  date: moment.Moment;
  type: BookingType;
  user: User;
  //this field is used to determine which documents are present in the booking
  whichDocuments: BookingDocuments;
  //this field is used to determine if the user should be prompted a login/auth to view the documents
  authPackageToggle: BookingDocuments;

  constructor(bookingData: BookingData) {
    this.date = moment(bookingData.date_time);
    this.type = new BookingType(bookingData.type);
    this.duration = bookingData.duration;
    // TODO: The backend needs to build the user from patient events
    this.user = bookingData.user ? new User(bookingData.user) : null;
    this.doctor = new Doctor(bookingData.doctor);
    this.uuid = bookingData.uuid;
    this.whichDocuments = bookingData.which_documents;
    this.authPackageToggle = bookingData.auth_package_toggle;
    this.telemedRoom = bookingData.telemed_room;
  }
}
