import * as moment from 'moment';
import { BookingType, BookingTypeData } from './booking-type.model';

interface DoctorAddress {
  code: string;
  town: string;
  line1: string;
  line2?: string;
  line3?: string;
  region: string;
  subregion: string;
}
interface Theme {
  logo: string;
  practice_logo: string;
  theme: { primary: string; accent: string };
}
export interface DoctorData extends Theme {
  client_code: string;
  code: string;
  uuid: string;
  isSaved: boolean;
  title: string;
  initials: string;
  name?: string;
  first_name: string;
  last_name: string;
  email_address?: string;
  primary_service: string;
  coordinates: { latitude: number; longitude: number };
  physical_address: DoctorAddress;
  postal_address: DoctorAddress;
  landline_nrs: string[];
  cellphone_nrs: string[];
  distance: number;
  booking_types?: BookingTypeData[];
  practice_name?: string;
  cancellation_hours: number;
  website?: string;
}

/**
 * Okay, let's define some things
 * Frontend doesn't discern between Doctors/Diaries/Medibase Person or Organization/Practices
 * It's not necessary, and clunks up the API, especially if we want to give another
 * company access to the API.
 *
 * So there is only one Doctor model, and by using some logic on the fields, it is
 * easy enough to work with the model.
 *
 * See the Truth Table Below:
 *
 * uuid   code  |  diary   medibase-linked   isValid
 * =============|====================================
 * 0      0     |   0             0             0
 * 0      1     |   0             1             1
 * 1      0     |   1             0             1
 * 1      1     |   1             1             1
 *
 * Using this, it's a bit easier to understand. Any code (be it a person or org code) means
 * that the entity is on medibase. Any uuid means that the entity is a diary, and takes bookings
 *
 * See? Easy. Using only the uuid and code, we can identify any entity on the backend.
 */

export class Doctor {
  uuid?: string;
  code?: string;
  distance: number;
  private readonly physicalAddressData: DoctorAddress;
  private readonly postalAddressData: DoctorAddress;
  landlines: string[];
  coordinates: { latitude: number; longitude: number };
  title: string;
  initials: string;
  firstName: string;
  lastName: string;
  service: string;
  emailAddress: string;
  bookingTypes: BookingType[];
  isSaved: boolean;
  theme: { primary: string; accent: string };
  cancellationHours: number;
  logo: string;
  practiceLogo: string;
  practiceName: string;
  website: string;

  constructor(data: DoctorData) {
    this.uuid = data.uuid;
    this.code = data.code;
    this.uuid = data.uuid;
    this.isSaved = data.isSaved;
    this.distance = data.distance;
    this.physicalAddressData = data.physical_address;
    this.postalAddressData = data.postal_address;
    this.landlines = data.landline_nrs;
    this.title = data.title;
    this.initials = data.initials;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.service = data.primary_service;
    this.coordinates = data.coordinates;
    this.emailAddress = data.email_address;
    this.logo = data.logo;
    this.practiceLogo = data.practice_logo;
    this.practiceName = data.practice_name;
    this.theme = data.theme;
    this.cancellationHours = data.cancellation_hours;
    this.bookingTypes = data.booking_types ? data.booking_types.map((res) => new BookingType(res)) : undefined;
    this.website = data.website;
  }

  get fullName() {
    return [this.title, this.firstName, this.lastName].join(' ');
  }

  get physicalAddress() {
    return this.physicalAddressData ? this.address(this.physicalAddressData) : '';
  }

  get postalAddress() {
    return this.address(this.postalAddressData);
  }

  get streetAddress() {
    return this.physicalAddressData.line1;
  }

  get earliestTimeslot() {
    const day = this.shortestBookingType?.earliestTimeslotDay;
    return day ? moment(`${day.timeslots[0]} ${day.date}`) : null;
  }

  get shortestBookingType() {
    if (this.bookingTypes) {
      // First we sort the bookings
      const sortedBookings = this.bookingTypes.slice().sort((a, b) => a.duration.asMinutes() - b.duration.asMinutes());
      // Then we try and find the shortest booking type with timeslots
      return sortedBookings.find((bookingType) => bookingType.earliestTimeslotDay);
    } else {
      return undefined;
    }
  }

  get availableBookingTypes() {
    return this.bookingTypes ? this.bookingTypes.map((b) => b.name) : [];
  }

  get hasBookings(): boolean {
    return !!this.bookingTypes?.length;
  }

  get hasInPersonBookings(): boolean {
    return this.bookingTypes?.some((bt) => !bt.telemed);
  }

  get hasTelemedBookings(): boolean {
    return this.bookingTypes?.some((bt) => bt.telemed);
  }

  get googleMapsLink() {
    return this.physicalAddress ? `https://google.com/maps/search/?api=1&query=${encodeURI(this.physicalAddress)}` : null;
  }

  private address(address: DoctorAddress) {
    return ['line1', 'line2', 'line3', 'town', 'region', 'code']
      .map((k) => address[k])
      .filter((v) => !!v)
      .join(', ');
  }

  getBookingType(name: string) {
    return this.bookingTypes?.filter((b) => b.name === name)[0];
  }

  hasTimeslot(timeslot: string, bookingType: string): boolean {
    const ts = moment(timeslot);
    const timeslotDay = this.getBookingType(bookingType).days.find((t) => t.date === ts.format('YYYY-MM-DD'));
    return !!timeslotDay && timeslotDay.timeslots.includes(ts.format('HH:mm'));
  }
}
