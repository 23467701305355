import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Booking } from '@models/booking.model';
import { feedbackTypes } from '@modules/shared/dialogs/feedback-dialog/feedback-dialog.component';
import { AlertService } from '@services/alert.service';
import { AuthService } from '@services/auth.service';
import { BookingService } from '@services/booking.service';
import { ChatMessage } from '@services/webrtc.service';
import { FeedbackService } from '@services/feedback.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, take, takeUntil, tap, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SimpleWebRTCService } from '@services/simple-web-rtc.service';
import { TelehealthOverlayComponent } from './telehealth-overlay/telehealth-overlay.component';

interface CallMessage extends ChatMessage {
  owner: 'local' | 'remote';
}

@Component({
  selector: 'gc-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.scss'],
})
export class TelehealthComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('localVideos', { static: true }) localVideos: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideos', { static: false }) remoteVideos: ElementRef<HTMLDivElement>;
  activeClientsNumber = 1;
  userName: string;
  activeCall = false;
  doctorArrived = false;
  private bookingUuid: string;
  booking$: Observable<Booking>;
  doctor: string;
  selectedBooking: Booking;
  videoPermission = false;
  audioPermission = false;
  private readonly unsubscribe$ = new Subject<void>();
  finishedLoading = false;
  @ViewChild('chatbox', { static: false }) chatbox: ElementRef<HTMLDivElement>;

  messageControl: FormControl = new FormControl('');
  messages: CallMessage[] = [];

  constructor(
    private readonly webRtc: SimpleWebRTCService,
    private readonly alert: AlertService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly bookingService: BookingService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly feedback: FeedbackService,
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.bookingUuid = this.route.snapshot.queryParams.booking;
      this.bookingService
        .getBooking$(this.bookingUuid)
        .pipe(
          filter((u) => !!u),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((booking) => {
          this.finishedLoading = true;
          this.selectedBooking = booking;
          this.doctor = this.selectedBooking.doctor.fullName;
          this.userName = this.selectedBooking.user.fullName;
        });
    } else {
      this.bookingUuid = this.route.snapshot.url[1].path;
      const identifier = this.route.snapshot.data.identifier;
      this.bookingService
        .getBooking$(this.bookingUuid, identifier)
        .pipe(
          catchError((err: unknown) => {
            this.alert
              .handleErrorDialog$(err, 'These details are not correct')
              .pipe(
                tap(() => {
                  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['eth', this.bookingUuid]);
                  });
                }),
              )
              .subscribe();
            return of(null);
          }),
          filter((u) => !!u),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((booking) => {
          if (booking.telemedRoom) {
            this.finishedLoading = true;
            this.selectedBooking = booking;
            this.doctor = this.selectedBooking.doctor.fullName;
            this.userName = this.selectedBooking.user.fullName;
          } else {
            this.alert.notifyError$('Error. This is not a valid telemed booking.');
            this.router.navigate(['landing']);
          }
        });
    }
    this.webRtc.insideRoom$.subscribe((inside) => {
      if (inside) {
        this.activeClientsNumber = this.activeClientsNumber + 1;
        this.doctorArrived = inside;
      } else {
        this.activeClientsNumber = this.activeClientsNumber - 1;
        // When the call starts there is no users
        if (this.activeClientsNumber < 1) {
          this.doctorArrived = false;
        }
      }
    });

    navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
      this.videoPermission = true;
    });
    navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
      this.audioPermission = true;
    });
  }

  ngAfterViewInit() {
    const config = { backdropClass: 'blur', hasBackdrop: true, disableClose: true };
    this.alert.openDialog$(TelehealthOverlayComponent, config);
  }

  ngOnDestroy() {
    if (this.isActiveCall() && this.finishedLoading) {
      this.webRtc.disconnect();
      this.activeCall = false;
    }
    this.unsubscribe$.next();
  }

  connectCall() {
    if (!this.videoPermission || !this.audioPermission) {
      this.alert.notifyError$('Please allow video and audio permissions to start the call');
      return;
    }
    this.webRtc.connect(this.remoteVideos, this.localVideos);
    this.activeCall = true;
    this.bookingService.joinRoom$(this.selectedBooking).pipe(take(1)).subscribe();
    this.webRtc.joinRoom(this.selectedBooking.telemedRoom);
    this.webRtc.messages$.subscribe((message) => {
      this.messages.push({
        timestamp: message.timestamp,
        owner: message.sender === this.webRtc.userName ? 'local' : 'remote',
        sender: message.sender,
        message: message.message,
      });
      this.cdr.detectChanges();
      this.scrollToBottom();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  isActiveCall() {
    return this.activeCall;
  }

  disconnectCall() {
    this.alert
      .confirmDialog$('Disconnect Call', { detail: 'Are you sure you wish to disconnect from this call?' })
      .pipe(
        switchMap((result) => {
          this.activeCall = false;
          if (result) {
            this.feedback.feedbackDialog(feedbackTypes.telemed)?.subscribe();
          }
          return of(result);
        }),
      )
      .subscribe((res) => {
        if (res) {
          this.webRtc.disconnect();
        }
      });
  }

  scrollToBottom() {
    this.chatbox.nativeElement.scrollTo({
      top: this.chatbox.nativeElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  sendMessage() {
    if (this.messageControl.value) {
      this.webRtc.sendMessage(this.messageControl.value);
    }
    this.messageControl.setValue('');
  }

  convertToDate(date: string) {
    return new Date(date);
  }
}
