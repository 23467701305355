import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DoctorCardComponent } from '@components/doctor-card/doctor-card.component';
import { BookingType } from '@models/booking-type.model';
import { Doctor } from '@models/doctor.model';
import { DisplayService } from '@services/display.service';

@Component({
  selector: 'gc-doctor-booking-card',
  templateUrl: './doctor-booking-card.component.html',
  styleUrls: ['./doctor-booking-card.component.scss'],
})
export class DoctorBookingCardComponent implements OnInit {
  @Input() doctor: Doctor;
  @Input() loadingTimeslots = false;
  maxTimeslotDisplay = 5;
  @ViewChild('doctorCard') doctorCard: DoctorCardComponent;

  constructor(private readonly disp: DisplayService) {}

  get shortestBookingType(): BookingType {
    return this.doctor.shortestBookingType;
  }

  get timeslots(): string[] {
    return this.shortestBookingType?.earliestTimeslotDay.timeslots.slice(0, this.maxTimeslotDisplay) ?? [];
  }

  get bookingButtonTitle(): string {
    // If there is no shortest booking today, just show 'Make A Booking' button
    return this.shortestBookingType ? 'More...' : 'Make a Booking';
  }

  ngOnInit(): void {
    this.disp.isMobile$.subscribe((isMobile) => (this.maxTimeslotDisplay = isMobile ? 2 : 5));
  }

  getTimeslots() {
    this.loadingTimeslots = true;
    this.doctor.bookingTypes = [];
    this.doctorCard.updateTimeslots();
  }
}
