<div class="container" gdColumns.gt-sm="repeat(3, 1fr)" gdRows="repeat(1, min-content)" gdGap="24px">
  <div class="doctor" gdColumn="1 / span 2" gdRow="1 / 1">
    <mat-card>
      <div class="remote-video" #remoteVideos>
        <div class="ready-text" *ngIf="!activeCall">
          Ready to join the Telehealth call with
          <br />
          <span class="doctor-name">{{ doctor }}</span>
        </div>
        <div class="ready-text" *ngIf="!doctorArrived && activeCall">
          Waiting for
          <span class="doctor-name">{{ doctor }}</span>
          ...
        </div>
        <div class="call-buttons" *ngIf="!activeCall">
          <button fxHide.gt-sm class="start-call" mat-raised-button type="button" (click)="connectCall()">
            <mat-icon svgIcon="phone"></mat-icon>
            Start call
          </button>
          <div class="permission-text-group flex-column-center">
            <div class="permission-text lex-row-center">
              <mat-icon class="icons">{{ videoPermission ? 'videocam' : 'videocam_off' }}</mat-icon>
              <span>Video {{ !videoPermission ? 'not' : '' }} allowed</span>
            </div>
            <div class="permission-text lex-row-center">
              <mat-icon class="icons">{{ audioPermission ? 'mic' : 'mic_off' }}</mat-icon>
              Audio {{ !audioPermission ? 'not' : '' }} allowed
            </div>
          </div>
        </div>
      </div>
      <mat-card-footer class="footer">
        <div class="call-buttons">
          <button *ngIf="activeCall" class="end-call" mat-raised-button type="button" (click)="disconnectCall()">
            <mat-icon>call_end</mat-icon>
            End call
          </button>
          <button *ngIf="!activeCall" class="start-call" mat-raised-button type="button" (click)="connectCall()" fxHide.lt-sm>
            <mat-icon svgIcon="phone"></mat-icon>
            Start call
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
  <div class="patient" gdRow="1 / 1">
    <mat-card>
      <div class="video-local">
        <video class="max-video-dimentions" #localVideos autoplay muted playsinline></video>
      </div>
    </mat-card>
  </div>
  <div class="border-radius-chat" gdColumn="1 / span 2">
    <div class="chatbox" #chatbox>
      <div class="chat-container">
        <div class="chat-box-title">Messages can only be sent during the call by people on the call</div>
        <div
          *ngFor="let message of messages"
          class="text-message"
          [ngClass]="{
            'doctor-message tri-left': message.owner === 'remote',
            'patient-message tri-right': message.owner === 'local'
          }"
        >
          <div>
            <p class="message">{{ message.message }}</p>
            <p class="message-timestamp">{{ convertToDate(message.timestamp) | localizedDate : 'shortTime' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chatbox-input" gdColumn="1 / span 2">
    <div>
      <mat-form-field appearance="outline" class="message-input" style="margin-bottom: -1.25em">
        <mat-label>Message</mat-label>
        <input [formControl]="messageControl" matInput placeholder="Type your message here" (keydown.enter)="sendMessage()" />
      </mat-form-field>
      <button mat-icon-button type="button" (click)="sendMessage()">
        <mat-icon svgIcon="send"></mat-icon>
      </button>
    </div>
  </div>
</div>
