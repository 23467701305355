import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DiaryFirstBookings } from '@models/first-timeslot.model';
import { Practice, PracticeData } from '@models/practice.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  constructor(private readonly http: HttpClient) {}

  getPractice$(identifier: string): Observable<Practice> {
    return this.http.get<PracticeData>(`/api/practice/${identifier}`).pipe(map((res) => new Practice(res)));
  }

  getLegacyDiaryUUID$(apiKey: string, diaryId: number): Observable<string> {
    const params: { [k: string]: string } = JSON.parse(
      JSON.stringify({
        api_key: apiKey,
        diary_id: diaryId,
      }),
    );
    return this.http.get<{ diary_uuid: string }>('/api/practice/diary/legacy', { params }).pipe(map((s) => s.diary_uuid));
  }

  getLegacyKioskUUID$(apiKey: string, diaryId: number): Observable<string> {
    const params: { [k: string]: string } = JSON.parse(
      JSON.stringify({
        api_key: apiKey,
        diary_id: diaryId,
      }),
    );
    return this.http.get<{ uuid: string }>('/api/kiosk/legacy', { params }).pipe(map((s) => s.uuid));
  }

  getLegacyTinyUrl(hash: any) {
    const params: { [k: string]: string } = JSON.parse(
      JSON.stringify({
        hash,
      }),
    );
    return this.http.get<{ url: string }>('/api/tinyurl/legacy', { params }).pipe(map((s) => s.url));
  }

  getPracticeTimeslots$(practiceUuid: string): Observable<DiaryFirstBookings[]> {
    return this.http.get<DiaryFirstBookings[]>(`/api/external/practice/${practiceUuid}/timeslots`);
  }
}
