<div fxLayout="row" fxLayoutAlign="center">
  <div class="cards">
    <mat-card fxLayoutGap="16px">
      <mat-card-header class="settings-header" fxLayoutGap="16px" fxLayout="row">
        <mat-icon color="primary" svgIcon="lock-reset"></mat-icon>
        <span class="larger">Password Settings</span>
      </mat-card-header>
      <mat-card-content>
        <div fxLayoutGap="5px" fxLayout.lt-lg="column" fxLayout.gt-md="row" fxLayoutAlign="center">
          <a mat-flat-button color="primary" [routerLink]="['/setup/settings/change-password']">
            <mat-icon svgIcon="lock-reset"></mat-icon>
            {{ (profile$ | async).hasPassword ? 'Change' : 'Set' }} your password
          </a>
          <a mat-flat-button color="primary" [routerLink]="['/setup/settings/reset-password']">
            <mat-icon svgIcon="lock-question"></mat-icon>
            Reset your password
          </a>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card fxLayoutGap="16px" fxLayout="column">
      <mat-card-header class="settings-header" fxLayoutGap="16px" fxLayout="row">
        <mat-icon color="primary" svgIcon="share-variant"></mat-icon>
        <span class="larger">Sharing &amp; Privacy</span>
      </mat-card-header>
      <mat-card-content>
        <!-- <div fxLayout="row">
          <div fxFlex="80">
            <p>When making a booking, ask me to share my medical information with the doctor.</p>
            <p class="italic">(If switched off, no medical information will be shared with the doctor)</p>
          </div>
          <div fxFlex="20">
            <span fxFlex="1 1 auto"></span>
            <mat-slide-toggle color="primary"></mat-slide-toggle>
          </div>
        </div>
        <div fxLayoutAlign="center center">
          <a mat-stroked-button color="primary">
            <mat-icon svgIcon="email-lock"></mat-icon>
            Email me all my data
          </a>
        </div> -->
        <div fxLayoutAlign="center center">
          <button mat-button mat-stroked-button color="primary" (click)="showPrivacyPolicy()">
            <mat-icon svgIcon="file-lock-outline"></mat-icon>
            View myGC's privacy policy
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card fxLayoutGap="16px" fxLayout="column">
      <div class="settings-header" fxLayoutGap="16px" fxLayout="row">
        <mat-icon color="primary" svgIcon="thumbs-up-down"></mat-icon>
        <span class="larger">Beta Tester</span>
      </div>
      <gc-beta-user [profile]="profile$ | async"></gc-beta-user>
    </mat-card>
    <!-- <mat-card fxLayoutGap="16px">
      <mat-card-header class="settings-header" fxLayoutGap="16px" fxLayout="row">
        <mat-icon color="primary" svgIcon="bullhorn"></mat-icon>
        <span class="larger">Communication Preferences</span>
      </mat-card-header>
      <mat-card-content>
        <p>Push Notifications will always be enabled if Email and SMS notifications are disabled.</p>
        <div class="divider"><span>from doctors</span></div>
        <div class="section">
          <div fxLayout="row">
            <span fxFlex="65" class="section-heading">Appointments</span>
            <span fxFlex="20" class="on-off">ALL ON/OFF</span>
            <div fxFlex="15">
              <span fxFlex="1 1 auto"></span>
              <mat-slide-toggle color="primary"></mat-slide-toggle>
            </div>
          </div>
          <p class="sub-text">Choose how you are notified about new bookings and booking reminders.</p>
        </div>
        <div class="option">
          <label class="toggle-label">Push messages</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Email</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">SMS</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="grey-divider"></div>
        <div class="section">
          <div fxLayout="row">
            <span fxFlex="65" class="section-heading">Questionnaires &amp; Consent Forms</span>
            <span fxFlex="20" class="on-off">ALL ON/OFF</span>
            <div fxFlex="15">
              <span fxFlex="1 1 auto"></span>
              <mat-slide-toggle color="primary"></mat-slide-toggle>
            </div>
          </div>
          <p class="sub-text">Choose how you are notified about questionnaires and consent forms from your doctor.</p>
        </div>
        <div class="option">
          <label class="toggle-label">Push messages</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Email</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">SMS</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="grey-divider"></div>
        <div class="section">
          <div fxLayout="row">
            <span fxFlex="65" class="section-heading">Medical Documents</span>
            <span fxFlex="20" class="on-off">ALL ON/OFF</span>
            <div fxFlex="15">
              <span fxFlex="1 1 auto"></span>
              <mat-slide-toggle color="primary"></mat-slide-toggle>
            </div>
          </div>
          <p class="sub-text">
            Choose how you are notified about new Medical Documents (scripts, sick notes, referral letters, etc.) from your doctor.
          </p>
        </div>
        <div class="option">
          <label class="toggle-label">Push messages</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Email</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="grey-divider"></div>
        <div class="section">
          <div fxLayout="row">
            <span fxFlex="65" class="section-heading">Other Requests</span>
            <span fxFlex="20" class="on-off">ALL ON/OFF</span>
            <div fxFlex="15">
              <span fxFlex="1 1 auto"></span>
              <mat-slide-toggle color="primary"></mat-slide-toggle>
            </div>
          </div>
          <p class="sub-text">Choose how you are notified about other types of requests (details, surveys, etc.) from your doctor.</p>
        </div>
        <div class="option">
          <label class="toggle-label">Push messages</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Email</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">SMS</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="grey-divider"></div>
        <div class="section">
          <div fxLayout="row">
            <span fxFlex="65" class="section-heading">Marketing</span>
            <span fxFlex="20" class="on-off">ALL ON/OFF</span>
            <div fxFlex="15">
              <span fxFlex="1 1 auto"></span>
              <mat-slide-toggle color="primary"></mat-slide-toggle>
            </div>
          </div>
          <p class="sub-text">Choose how you are notified about marketing messages from your doctor.</p>
        </div>
        <div class="option">
          <label class="toggle-label">Push messages</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Email</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">SMS</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="divider"><span>from myGC</span></div>
        <div class="option">
          <label class="toggle-label">New features</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
        <div class="option">
          <label class="toggle-label">Marketing</label>
          <span fxFlex="1 1 auto"></span>
          <mat-slide-toggle></mat-slide-toggle>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header class="settings-header" fxLayoutGap="16px" fxLayout="row">
        <mat-icon color="primary" svgIcon="block-helper"></mat-icon>
        <span class="larger">Blocked Doctors</span>
      </mat-card-header>
      <p>
        Block specific doctors from sending you
        <strong>any</strong>
        notifications or messages.
      </p>
      <mat-form-field fxFill>
        <mat-label>Search for a doctor to block</mat-label>
        <mat-icon svgIcon="magnify" matPrefix></mat-icon>
        <input matInput type="text" />
      </mat-form-field>
      <mat-list>
        <mat-list-item class="doctor">
          <div>
            <img src="assets/images/avatar.png" alt="Doctor logo" />
            <mat-icon class="block" color="warn" svgIcon="block-helper"></mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start">
            <span>Dr Poggenpoel</span>
            <span>General Practitioner</span>
          </div>
          <span fxFlex="1 1 auto"></span>
          <mat-icon color="primary" svgIcon="lock-open-outline"></mat-icon>
        </mat-list-item>
      </mat-list>
    </mat-card> -->
    <div class="delete" fxLayoutAlign="center center">
      <a mat-flat-button color="warn" (click)="onDeleteAccountClick()">
        <mat-icon svgIcon="delete-forever"></mat-icon>
        Delete my account
      </a>
    </div>
  </div>
</div>
