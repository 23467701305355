import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, GridModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ContactDetailsComponent } from '@components/contact-details/contact-details.component';
import { CustomCalendarComponent, CustomCalendarHeaderComponent } from '@components/custom-calendar/custom-calendar.component';
import {
  CustomTabBodyComponent,
  CustomTabBodyHostDirective,
  CustomTabComponent,
  CustomTabContainerComponent,
} from '@components/custom-tab-container/custom-tab-container.component';
import { DoctorCardComponent } from '@components/doctor-card/doctor-card.component';
import { InputFieldComponent } from '@components/input-field/input-field.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { MemberInformationComponent } from '@components/member-information/member-information.component';
import { PaymentsComponent } from '@components/payments/payments.component';
import { PaymentDialogComponent, ViewPaymentComponent } from '@components/payments/view-payment/view-payment.component';
import { ViewPaymentOptionsComponent } from '@components/payments/view-payment/options/view-payment-options.component';
import { PictureComponent } from '@components/picture/picture.component';
import { ResetPasswordComponent } from '@components/reset-password/reset-password.component';
import { SidebarNavComponent } from '@components/sidebar-nav/sidebar-nav.component';
import {
  StepsHeaderCompleteDirective,
  StepsHeaderComponent,
  StepsHeaderLabelDirective,
  StepsHeaderStepComponent,
} from '@components/steps-header/steps-header.component';
import { SwipeNotificationsComponent } from '@components/swipe-notifications/swipe-notifications.component';
import { VerticalStepperComponent } from '@components/vertical-stepper/vertical-stepper.component';
import { AboutDialogComponent } from '@dialogs/about-dialog/about-dialog.component';
import { CameraComponent } from '@dialogs/camera/camera.component';
import { InputDialogComponent } from '@dialogs/input-dialog/input-dialog.component';
import { FeedbackDialogComponent } from '@dialogs/feedback-dialog/feedback-dialog.component';
import { MessageDialogComponent } from '@dialogs/message-dialog/message-dialog.component';
import { TermsAndConditionsDialogComponent } from '@dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { TimeoutDialogComponent } from '@dialogs/timeout-dialog/timeout-dialog.component';
import { AutofocusDirective } from '@directives/autofocus.directive';
import { FormFieldDirective } from '@directives/form-field.directive';
import { InputMaskDirective } from '@directives/input-mask.directive';
import { NoAutocompleteDirective } from '@directives/no-autocomplete.directive';
import { NullOnEmptyDirective } from '@directives/null-on-empty.directive';
import { ScrollContainerDirective } from '@directives/scroll-container.directive';
import { AddressFilterPipe } from '@pipes/address-filter.pipe';
import { DistancePipe } from '@pipes/distance.pipe';
import { LocalizedDatePipe } from '@pipes/localizedDate.pipe';
import { SafePipe } from '@pipes/safe.pipe';
import { TodayTomorrowPipe } from '@pipes/today-tomorrow.pipe';
import { AngularSplitModule } from 'angular-split';
import { WebcamModule } from 'ngx-webcam';
import { LogButtonDirective } from '@directives/log-button.directive';
import { MaterialModule } from '../material/material.module';
import { BreadcrumbBarComponent } from './breadcrumb-bar/breadcrumb-bar.component';
import { AddressComponent } from './components/address/address.component';
import { BookingDisplayCardComponent } from './components/booking-display-card/booking-display-card.component';
import { DoctorActionsComponent } from './components/doctor-actions/doctor-actions.component';
import { DoctorBookingCardComponent } from './components/doctor-booking-card/doctor-booking-card.component';
import { DoctorListComponent } from './components/doctor-list/doctor-list.component';
import { GrowerComponent } from './components/grower/grower.component';
import { HeaderComponent } from './components/header/header.component';
import { LoggedOutHeaderComponent } from './components/logged-out-header/logged-out-header.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginSplashComponent } from './components/login-splash/login-splash.component';
import { MedicalAidDetailsComponent } from './components/medical-aid-details/medical-aid-details.component';
import { PaymentDisplayCardComponent } from './components/payment-display-card/payment-display-card.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SignatureInputComponent } from './components/signature-input/signature-input.component';
import { DrawableImageComponent } from './components/drawable-image/drawable-image.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { TypeFilterComponent } from './components/type-filter/type-filter.component';
import { DeleteAccountComponent } from './dialogs/delete-account/delete-account.component';
import { WhyFaceIdDialogComponent } from './dialogs/why-face-id-dialog/why-face-id-dialog.component';
import { LoginComponent } from './components/login-form/login/login.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { GoogleLocationComponent } from './components/google-location/google-location.component';
import { BetaUserComponent } from './components/beta-user/beta-user.component';
import { ContactButtonComponent } from './components/contact-button/contact-button.component';
import { RetryDialogComponent } from './dialogs/retry-dialog/retry-dialog.component';

@NgModule({
  declarations: [
    AboutDialogComponent,
    AddressFilterPipe,
    DistancePipe,
    LocalizedDatePipe,
    HeaderComponent,
    InputDialogComponent,
    FeedbackDialogComponent,
    TodayTomorrowPipe,
    ScrollContainerDirective,
    NullOnEmptyDirective,
    NoAutocompleteDirective,
    FormFieldDirective,
    InputFieldComponent,
    LoaderComponent,
    MessageDialogComponent,
    VerticalStepperComponent,
    DoctorCardComponent,
    CustomCalendarComponent,
    CustomCalendarHeaderComponent,
    CustomTabContainerComponent,
    CustomTabComponent,
    CustomTabBodyComponent,
    CustomTabBodyHostDirective,
    StepsHeaderComponent,
    StepsHeaderStepComponent,
    StepsHeaderCompleteDirective,
    StepsHeaderLabelDirective,
    SidebarNavComponent,
    SearchInputComponent,
    AutofocusDirective,
    SafePipe,
    DoctorActionsComponent,
    GrowerComponent,
    ContactButtonComponent,
    TypeFilterComponent,
    LoginSplashComponent,
    LoginFormComponent,
    DoctorBookingCardComponent,
    DoctorListComponent,
    ProfilePictureComponent,
    LoggedOutHeaderComponent,
    BreadcrumbBarComponent,
    TermsAndConditionsDialogComponent,
    TermsAndConditionsComponent,
    TimeoutDialogComponent,
    DeleteAccountComponent,
    BookingDisplayCardComponent,
    CameraComponent,
    SignatureInputComponent,
    DrawableImageComponent,
    SwipeNotificationsComponent,
    PaymentDisplayCardComponent,
    AddressComponent,
    PaymentsComponent,
    ViewPaymentComponent,
    ViewPaymentOptionsComponent,
    PaymentDialogComponent,
    MedicalAidDetailsComponent,
    ContactDetailsComponent,
    MemberInformationComponent,
    PictureComponent,
    InputMaskDirective,
    LogButtonDirective,
    WhyFaceIdDialogComponent,
    ResetPasswordComponent,
    LoginComponent,
    GoogleLoginComponent,
    GoogleLocationComponent,
    BetaUserComponent,
    RetryDialogComponent,
  ],
  imports: [
    AngularSplitModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    GridModule,
    MaterialModule,
    CdkStepperModule,
    A11yModule,
    RouterModule,
    GoogleMapsModule,
    WebcamModule,
  ],
  exports: [
    AngularSplitModule,
    HeaderComponent,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    GridModule,
    AddressFilterPipe,
    DistancePipe,
    LocalizedDatePipe,
    TodayTomorrowPipe,
    ScrollContainerDirective,
    FormFieldDirective,
    InputFieldComponent,
    LoaderComponent,
    VerticalStepperComponent,
    DoctorCardComponent,
    CdkStepperModule,
    CustomCalendarComponent,
    CustomTabComponent,
    CustomTabContainerComponent,
    CustomTabBodyComponent,
    StepsHeaderComponent,
    StepsHeaderStepComponent,
    StepsHeaderCompleteDirective,
    StepsHeaderLabelDirective,
    SidebarNavComponent,
    SearchInputComponent,
    AutofocusDirective,
    SafePipe,
    DoctorActionsComponent,
    GrowerComponent,
    ContactButtonComponent,
    TypeFilterComponent,
    LoginSplashComponent,
    LoginFormComponent,
    LoginComponent,
    DoctorBookingCardComponent,
    DoctorListComponent,
    ProfilePictureComponent,
    LoggedOutHeaderComponent,
    BreadcrumbBarComponent,
    TermsAndConditionsDialogComponent,
    TermsAndConditionsComponent,
    BookingDisplayCardComponent,
    GoogleMapsModule,
    CameraComponent,
    SignatureInputComponent,
    DrawableImageComponent,
    SwipeNotificationsComponent,
    PaymentDisplayCardComponent,
    AddressComponent,
    PaymentsComponent,
    ViewPaymentComponent,
    ViewPaymentOptionsComponent,
    PaymentDialogComponent,
    MedicalAidDetailsComponent,
    ContactDetailsComponent,
    MemberInformationComponent,
    PictureComponent,
    InputMaskDirective,
    LogButtonDirective,
    ResetPasswordComponent,
    GoogleLoginComponent,
    GoogleLocationComponent,
    BetaUserComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        autoFocus: true,
        restoreFocus: true,
        closeOnNavigation: true,
      },
    },
  ],
  entryComponents: [
    LoaderComponent,
    InputDialogComponent,
    FeedbackDialogComponent,
    MessageDialogComponent,
    TimeoutDialogComponent,
    CustomCalendarHeaderComponent,
    DeleteAccountComponent,
    PaymentDialogComponent,
    RetryDialogComponent,
  ],
})
export class SharedModule {}
