<div fxLayoutGap="16px" class="block-email-change-page" fxLayout="column" fxLayoutAlign="space-around center">
  <mat-card>
    <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
      <mat-card-title>Hello, welcome back!</mat-card-title>
      <mat-card-subtitle fxLayoutAlign="center center">
        <span>The request for the new email was blocked.</span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">
      <p>
        A password reset link has been sent to your email
        <br />
        and if you are using a Google account
        <br />
        please confirm that it has not been compromised.
        <br />
        Please click on the button below to continue.
        <br />
      </p>
      <button mat-button type="button" (click)="continue()">Go to Login</button>
    </mat-card-content>
  </mat-card>
</div>
