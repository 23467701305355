<mat-card class="have-account">
  <div class="img-container" fxLayoutAlign.gt-sm="center center">
    <img src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
  </div>
  <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
    <mat-card-title>Already have an account?</mat-card-title>
    <mat-card-subtitle>
      Log back in to access all
      <br />
      <b><i>myGC's</i></b>
      great features!
    </mat-card-subtitle>
  </mat-card-header>
  <div fxLayout="column" fxLayoutAlign="center center">
    <button class="login-btn" mat-raised-button type="button" routerLink="/login">
      <mat-icon svgIcon="login"></mat-icon>
      <span>Log in</span>
    </button>
  </div>
</mat-card>
