<div class="container" fxLayout="column" fxLayoutAlign="start center">
  <div class="content" fxLayout="column">
    <gc-logged-out-header></gc-logged-out-header>
    <div fxLayout="row" fxLayoutAlign="start center" class="logo-backdrop" fxHide.gt-sm>
      <button class="back-btn" mat-button type="button" routerLink="/landing">
        <mat-icon svgIcon="keyboard-backspace"></mat-icon>
        Back
      </button>
    </div>
    <div fxLayout="row" gdRow="2" gdColumn="1 / span 4" gdColumn.gt-sm="2 / span 2">
      <div fxFlex="1 1 0">
        <gc-login-form (loginSuccessful)="login()"></gc-login-form>
      </div>
      <mat-card class="signup-card" fxHide.lt-md>
        <div fxLayoutAlign.gt-sm="center center">
          <img class="img-container" src="assets/images/myGCLogo_default-white.svg" alt="myGC Logo" />
        </div>
        <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">
          <mat-card-title>Don't have an account yet?</mat-card-title>
          <mat-card-subtitle>
            Sign up to get access to all
            <br />
            myGC's great features!
          </mat-card-subtitle>
        </mat-card-header>
        <div fxLayout="column" fxLayoutAlign="center center">
          <button class="signup-btn" mat-raised-button type="button" routerLink="/register">
            <mat-icon svgIcon="account-plus"></mat-icon>
            <span>Sign up</span>
          </button>
        </div>
      </mat-card>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" gdRow="3" gdColumn="1 / span 4" fxHide.gt-sm fxLayoutGap="10px">
      <button class="signup-btn" mat-raised-button type="button" routerLink="/register">
        <mat-icon svgIcon="account-plus"></mat-icon>
        <span>Sign up</span>
      </button>
      <button color="accent" mat-raised-button type="button" (click)="feedback()">
        <mat-icon svgIcon="comment-quote"></mat-icon>
        Feedback
      </button>
    </div>
  </div>
</div>
