<div class="login-form">
  <mat-card class="login-card" gdColumn.lt-md="1 / span 4">
    <mat-card-header fxLayout="column" fxLayoutAlign="center stretch">Hello, welcome back!</mat-card-header>
    <mat-card-subtitle fxLayoutAlign="center center" fxHide.gt-sm>
      <span>
        Log in to continue to your
        <b><i>myGC</i></b>
        account
      </span>
    </mat-card-subtitle>
    <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
      <gc-login (loginSuccessful)="login()"></gc-login>
    </mat-card-content>
  </mat-card>
</div>
